import React from 'react';

import Layout from '../../components/layout';
import './index.sass';
import SEO from '../../components/seo';

const Index = () => {
  return (
      <Layout pageType="blog">
        <SEO
            keywords={['employee directory change log']}
            title="Node Vision | Employee Directory - Change Log"
        />
        <div className="ed-change-log">
          <section className="heading">
            <h1>Employee Directory - Change Log</h1>
          </section>
          <section className="content">
            <div className="container">
              <div className="version-label">v6.6.9.1</div>
              <div>
                <ul>
                  <li>Bux fixes.</li>
                </ul>
              </div>

              <div className="version-label">v6.6.8.4</div>
              <div>
                <ul>
                  <li>Added <strong>enableAutoLoadMore</strong> setting to enable automatic loading of users when scrolling down.</li>
                </ul>
              </div>
              <div className="version-label">v6.6.8.2</div>
              <div>
                <ul>
                  <li>Added <strong>hideMailboxes</strong> search term for hiding all mailboxes from the app.</li>
                </ul>
              </div>
              <div className="version-label">v6.6.8.0</div>
              <div>
                <ul>
                  <li>Improved user photo loading.</li>
                </ul>
              </div>
              <div className="version-label">v6.6.7.4</div>
              <div>
                <ul>
                  <li>Fix for the app icon.</li>
                </ul>
              </div>
              <div className="version-label">v6.6.7.0</div>
              <div>
                <ul>
                  <li>Upgraded dependencies to the latest versions.</li>
                </ul>
              </div>
              <div className="version-label">v6.6.6.2</div>
              <div>
                <ul>
                  <li>Fixed broken Graph API calls.</li>
                  <li>Fix for user images not loading in a user detail view.</li>
                </ul>
              </div>
              <div className="version-label">v6.6.2.9</div>
              <div>
                <ul>
                  <li>A list based managed metadata value can be formatted as a HTML list. E.g. <strong>&lt;div&gt;&#123;formatAsList(user?.profileData?.skills)&#125;&lt;/div&gt;</strong></li>
                </ul>
              </div>
              <div className="version-label">v6.6.2.5</div>
              <div>
                <ul>
                  <li>The web part accepts the <strong>searchFor</strong> and <strong>additionalSearchTerms</strong> settings through URL parameters.
                    For more, see the app configuration section.</li>
                </ul>
              </div>
              <div className="version-label">v6.6.1.4</div>
              <div>
                <ul>
                  <li>Minor fixes.</li>
                </ul>
              </div>
              <div className="version-label">v6.6.1.2</div>
              <div>
                <ul>
                  <li>Minor fixes.</li>
                </ul>
              </div>
              <div className="version-label">v6.6.1.1</div>
              <div>
                <ul>
                  <li>Minor fixes.</li>
                </ul>
              </div>
              <div className="version-label">v6.6.0.8</div>
              <div>
                <ul>
                  <li>Fix for broken search functionality caused by Microsoft Search API update.</li>
                </ul>
              </div>
              <div className="version-label">v6.6.0.6</div>
              <div>
                <ul>
                  <li>Fix for "Disabled accounts are hidden in Direct reports section" which wasn't working in some situations.</li>
                </ul>
              </div>
              <div className="version-label">v6.6.0.4</div>
              <div>
                <ul>
                  <li>Disabled accounts are hidden in Direct reports section</li>
                </ul>
              </div>
              <div className="version-label">v6.6.0.3</div>
              <div>
                <ul>
                  <li>Bug fixes</li>
                </ul>
              </div>
              <div className="version-label">v6.6.0.2</div>
              <div>
                <ul>
                  <li>Bug fixes</li>
                </ul>
              </div>
              <div className="version-label">v6.6.0.1</div>
              <div>
                <ul>
                  <li>Updated <i>outlookPhotoUrl</i> setting to <strong>/_vti_bin/DelveApi.ashx/people/profileimage?size=L&userId={'{{'}primaryEmail,true{'}}'}</strong> as some users were experiencing issue with the previous value.</li>
                </ul>
              </div>
              <div className="version-label">v6.6.0.0</div>
              <div>
                <ul>
                  <li>A new setting <strong>noOfRequiredLettersToStartSearching</strong> added. If specified, the directory will not start displaying any users until the specified number characters is entered in the search field.</li>
                  <li>Additional settings available for the custom filters.</li>
                </ul>
              </div>
              <div className="version-label">v6.5.12.0</div>
              <div>
                <ul>
                  <li>A default value of the filter field drop down box can be changed now.</li>
                </ul>
              </div>
              <div className="version-label">v6.5.11.0</div>
              <div>
                <ul>
                  <li>Fix for the broken Microsoft Photo service. If the user photos stop appearing, please update your app and replace the <strong>outlookPhotoUrl</strong> setting with this value: <strong>https://{"{{"}searchData.geoLocationSource{"}}"}.delve.office.com/mt/v3/people/profileimage?userId={"{{"}primaryEmail,true{""}}"}&size=L</strong></li>
                </ul>
              </div>
              <div className="version-label">v6.5.10.0</div>
              <div>
                <ul>
                  <li>The <strong>additionalSearchTerms can</strong> can be passed in URL. E.g. https://contoso.sharepoint.com/sites/directory?additionalSearchTerm=Department:"Finance"</li>
                </ul>
              </div>
              <div className="version-label">v6.5.9.0</div>
              <div>
                <ul>
                  <li>Fix for isEmpty and notEmpty search commands in the additionalSearchTerms setting.</li>
                  <li>The tab alignment is now configurable.</li>
                </ul>
              </div>
              <div className="version-label">v6.5.7.0</div>
              <div>
                <ul>
                  <li>Reset icon is configurable now.</li>
                  <li>The tab alignment is now configurable.</li>
                </ul>
              </div>
              <div className="version-label">v6.5.4.0</div>
              <div>
                <ul>
                  <li>Fix for custom filters (applied exact matching).</li>
                </ul>
              </div>
              <div className="version-label">v6.5.3.0</div>
              <div>
                <ul>
                  <li>Bug fixes.</li>
                </ul>
              </div>
              <div className="version-label">v6.5.2.0</div>
              <div>
                <ul>
                  <li>Added <strong>disableGraphApi</strong> setting to disable all Graph API calls to significantly improve loading speed. If set to <strong>true</strong>, some user properties won't be loaded (e.g. presence indicator).</li>
                </ul>
              </div>
              <div className="version-label">v6.4.9.0</div>
              <div>
                <ul>
                  <li>Added <strong>Reset search</strong> button</li>
                  <li>Fix for <strong>isEmpty</strong> search term</li>
                </ul>
              </div>
              <div className="version-label">v6.4.3.0</div>
              <div>
                <ul>
                  <li>Changed a web part icon.</li>
                </ul>
              </div>
              <div className="version-label">v6.4.1.0</div>
              <div>
                <ul>
                  <li>
                    Added <strong>Recently modified documents</strong> to a user
                    toolbar.
                  </li>
                  <li>
                    Updated a table view template so it doesn't overflow (a
                    scrollbar is displayed)
                  </li>
                  <li>
                    Added a new "Minimal" user template in the{' '}
                    <strong>Configuration -> User Template</strong>
                  </li>
                  <li>
                    Fixed an issue with sorting not applying when on the second
                    page.
                  </li>
                </ul>
                <p>
                  If you are upgrading, you will need to reset the settings or add
                  a new web part for changes to take effect (changes were made in
                  the templates).
                </p>
              </div>
            </div>
          </section>
        </div>
      </Layout>
  );
};

export default Index;
